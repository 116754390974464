import { FC } from 'react'

export type JsonLinkedDataProps = {
  data: FAQPageLinkedData | WebSiteLinkedData
}

export const JsonLinkedData: FC<JsonLinkedDataProps> = (props) => {
  return (
    <script
      id={props.data['@type']}
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(props.data),
      }}
    />
  )
}

export type FAQPageLinkedData = {
  '@context': 'https://schema.org'
  '@type': 'FAQPage'
  mainEntity: {
    '@type': 'Question'
    name: string
    acceptedAnswer: {
      '@type': 'Answer'
      text: string
    }
  }[]
}

export type WebSiteLinkedData = {
  '@context': 'https://schema.org'
  '@type': 'WebSite'
  name: string
  alternateName: string
  url: string
}

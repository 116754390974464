import styled from '@emotion/styled'
import { Block } from 'components/Block'
import { Button } from 'components/button/Button'
import { Text, Title } from 'components/Typography'
import { BREAKPOINTS } from 'utils/styled'

export const SBlock = styled(Block)``
export const SGridWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  @media ${BREAKPOINTS.MD.min} {
    grid-template-columns: 1fr 1fr;
    padding: 6rem 0;
  }
`

export const SLeftColumn = styled.div``

export const STitle = styled(Title)`
  text-align: left;
  margin-bottom: 1.5rem;
  @media ${BREAKPOINTS.SM.min} {
    text-align: center;
  }
  @media ${BREAKPOINTS.MD.min} {
    text-align: left;
  }
`

export const SCaption = styled(Text)`
  display: block;
  text-transform: none;
  font-weight: 400;
  text-align: center;
  @media ${BREAKPOINTS.MD.min} {
    text-align: left;
  }
`

export const SMoreButton = styled(Button)`
  margin: 3rem auto 0;
  width: fit-content;
`

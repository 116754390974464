import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { StyledLinkProps, BREAKPOINTS } from 'utils/styled'

export const SContainer = styled.div<StyledLinkProps>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
`

export const SMainContent = styled.div<{ hasFooter?: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  // Overflow property removed, because of sticky troubles

  ${({ hasFooter }) =>
    hasFooter
      ? css`
          min-height: calc(100vh - 150px);

          @media ${BREAKPOINTS.MD.max} {
            min-height: 100vh;
          }
        `
      : null}
`

export const SFooterHeaderContainer = styled.div`
  flex-shrink: 0;
`

export const SHeaderContainer = styled(SFooterHeaderContainer)<{ sticky?: boolean }>`
  position: ${({ sticky }) => (sticky ? 'sticky' : 'static')};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
`

import { InViewGuard } from 'components/InViewGuard'
import { ReactNode, forwardRef, DOMAttributes, useState, useMemo } from 'react'
import { StyledLinkProps } from 'utils/styled'
import { SContainer, SFooterHeaderContainer, SHeaderContainer, SMainContent } from './Layout.styled'
import { layoutContext } from './context'
import { InterpolationPrimitive } from '@emotion/serialize'

export const Layout = forwardRef<
  HTMLDivElement,
  {
    header?: ReactNode
    footer?: ReactNode
    mainContentCss?: InterpolationPrimitive
    noStickyHeader?: boolean
  } & StyledLinkProps &
    DOMAttributes<HTMLDivElement>
>(({ header, footer, children, as, noStickyHeader, ...props }, ref) => {
  const [scrolled, setScrolled] = useState(false)
  const context = useMemo(() => ({ scrolled }), [scrolled])

  return (
    <layoutContext.Provider value={context}>
      <SContainer as={as} {...props}>
        <InViewGuard setIsInView={(inView) => setScrolled(!inView)} />
        <SHeaderContainer sticky={!noStickyHeader}>{header}</SHeaderContainer>
        <SMainContent css={props.mainContentCss} hasFooter={!!footer} ref={ref}>
          {children}
        </SMainContent>
        <SFooterHeaderContainer>{footer}</SFooterHeaderContainer>
      </SContainer>
    </layoutContext.Provider>
  )
})
Layout.displayName = 'Layout'

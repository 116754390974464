import { usePromotions } from 'api/goodtrust/promo'
import { describePromoOffer } from 'logic/promo/describePromoOffer'
import { useSubscriptionItem } from 'logic/subscription/useSubscriptionItem'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { setPlanCart } from 'utils/cart'
import { IPlanCart } from 'utils/cart/types'
import { cobrandingLogic } from 'utils/cobranding/logic'
import { useOutsideCobranding } from 'utils/cobranding/useOutsideCobranding'
import { ident } from 'utils/general'
import { useExtendedPromotion } from 'utils/promo'

export function useCobrandedOffer() {
  const { isLogged } = useAuth()
  const subItem = useSubscriptionItem('ESTATE_PLAN')?.DEFAULT
  const outsideCobranding = useOutsideCobranding()
  const sessionValue = cobrandingLogic.useSessionValue()
  const sessionPromotion = useExtendedPromotion(sessionValue?.promoCode).data

  const cobrandedUserPromoCode = usePromotions().data?.json?.promo_codes?.find((promo) => {
    return (
      outsideCobranding?.reason === 'cobranded-user' && promo.code != null && promo.partner != null
    )
  })?.code

  const cobrandedUserPromotion = useExtendedPromotion(cobrandedUserPromoCode).data
  const promo = cobrandedUserPromotion ?? sessionPromotion

  const estatePlanOffer = promo?.findRedeemableDiscountOffer('ESTATE_PLAN')

  const flowType =
    sessionPromotion?.cobrandedPartner != null && !isLogged
      ? 'cobranded-flow'
      : 'straight-to-directive'

  const discountedPriceInCents = estatePlanOffer
    ? describePromoOffer(estatePlanOffer).getDiscountedPrice(subItem?.price)
    : undefined
  const discountPercentage =
    discountedPriceInCents != null && subItem?.price != null
      ? (1 - discountedPriceInCents / subItem.price) * 100
      : 0

  const partnerResponse =
    outsideCobranding?.partner === 'take-from-offer'
      ? promo?.cobrandedPartner
      : outsideCobranding?.partner

  const partnerFields = {
    partner: partnerResponse,
    partnerDisplayName: partnerResponse?.title,
    partnerImageUrl: partnerResponse?.partner_photo?.url,
  }

  const offer =
    discountedPriceInCents != null && partnerFields.partner != null
      ? {
          discountPercentage,
          discountedPriceInCents,
          ...partnerFields,
        }
      : undefined

  const show = outsideCobranding != null

  return {
    ctx: {
      promo,
      estatePlanOffer,
    },
    get navigation() {
      return {
        partner: show ? partnerFields : undefined,
      }
    },
    get estatePlanningHero() {
      return {
        offer: show ? offer : undefined,
        flowType,
      }
    },
    get signup() {
      const showAtSignUp = flowType === 'cobranded-flow'
      const afterSignup = {
        redirectToCheckout:
          promo && estatePlanOffer
            ? {
                planCart: ident<IPlanCart>({
                  type: 'ESTATE_PLAN',
                  firstSelectedType: 'ESTATE_PLAN',
                  noShowUpsell: true,
                  promo: {
                    promoCode: promo.code,
                    offer: estatePlanOffer,
                    partner: promo.promo_code_info?.partner,
                  },
                }),
              }
            : undefined,
      }
      return {
        offer: showAtSignUp ? offer : undefined,
        shouldAutoAcceptOffer: (signupPromoCode: string | undefined) => {
          return signupPromoCode === sessionValue?.promoCode && showAtSignUp
        },
        onMaySetPlanCart: async () => {
          if (afterSignup.redirectToCheckout) {
            setPlanCart(afterSignup.redirectToCheckout.planCart)
          }
        },
        afterSignup: {
          shouldRedirectToCheckout: afterSignup.redirectToCheckout != null,
        },
      }
    },
  } as const
}

export type CobrandedOfferLogic = ReturnType<typeof useCobrandedOffer>

import { INLINES } from '@contentful/rich-text-types'
import { ContentfulContent } from 'components/content/contentfulContent/ContentfulContent'
import { FaQuestionList } from 'components/faqQuestionList/FaQuestionList'
import { JsonLinkedData } from 'components/jsonLinkedData/JsonLinkedData'
import { BasicLink, PrimaryLink } from 'components/Link'
import { Spacer } from 'components/spacer/Spacer'
import { Title_h3_default } from 'components/Typography'
import { describeFAQ } from 'logic/faq/describe'
import { Trans, useTranslation } from 'next-i18next'
import React from 'react'
import { colors } from 'utils/colors'
import { SUPPORT_URL } from 'utils/constants'
import { useWindowSize } from 'utils/hooks'
import { SBlock, SCaption, SGridWrapper, SLeftColumn, STitle } from './FaqTwoColumn.styled'
import { FAQuestionsProps, TranslatedQuestion } from './types'

export function FaqTwoColumns({
  content,
  className,
  title,
  titleCss,
  titleVariant,
}: FAQuestionsProps) {
  const { t } = useTranslation()
  const { isDesktop } = useWindowSize()

  const { getQuestions, asLinkedData } = describeFAQ(content)

  const translatedQuestions =
    getQuestions().map(
      (q): TranslatedQuestion => ({
        key: q.sys.id,
        answer: q.answer && (
          <ContentfulContent
            data={q.answer}
            components={{ [INLINES.HYPERLINK]: <PrimaryLink /> }}
          />
        ),
        question: q.question ?? '',
      })
    ) ?? []

  return (
    <>
      {content && <JsonLinkedData data={asLinkedData()} />}

      <SBlock className={className}>
        <SGridWrapper>
          <SLeftColumn>
            <STitle
              variant={titleVariant ?? Title_h3_default}
              as="h3"
              css={titleCss}
              color={colors.gray[100]}
            >
              {title ?? t('common.section_faq.two_column_title')}
            </STitle>
            <SCaption>
              <Trans
                t={t}
                i18nKey="common.section_faq.two_column_caption"
                context={isDesktop ? 'desktop' : 'mobile'}
                components={[<BasicLink key="0" href={SUPPORT_URL} target={'_blank'} />]}
              />
            </SCaption>
            <Spacer size={[1.5, 3]} />
          </SLeftColumn>
          <FaQuestionList translatedQuestions={translatedQuestions} />
        </SGridWrapper>
      </SBlock>
    </>
  )
}

import { TranslatedQuestion } from 'components/faqTwoColumn/types'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { isElementInViewport, scrollToRect, sleep } from 'utils/general'
import {
  SAnswer,
  SAnswerText,
  SAnswerWrapper,
  SQuestionList,
  SQuestionListItem,
  SQuestionListItemIcon,
} from './FaQuestionList.styled'

const ANIMATION_DURATION_SECONDS = 0.2
const ANIMATION_DURATION_MS = ANIMATION_DURATION_SECONDS * 1000

export const FaQuestionList = ({
  translatedQuestions,
}: {
  translatedQuestions: TranslatedQuestion[]
}) => {
  const activeQuestionRef = useRef<HTMLLIElement | null>(null)
  const [active, setActive] = useState<string | null>()

  useEffect(() => {
    const scrollIntoActiveQ = async () => {
      await sleep(ANIMATION_DURATION_MS + 100)
      if (active && activeQuestionRef.current && !isElementInViewport(activeQuestionRef.current)) {
        scrollToRect(activeQuestionRef.current.getBoundingClientRect())
      }
    }

    scrollIntoActiveQ()
  }, [active])

  return (
    <SQuestionList>
      {translatedQuestions.map((question) => (
        <li
          ref={question.key === active ? activeQuestionRef : undefined}
          css={{ width: '100%' }}
          key={question.key}
        >
          <SQuestionListItem
            active={question.key === active}
            onClick={() =>
              setActive((activeKey) => (activeKey === question.key ? null : question.key))
            }
          >
            <SQuestionListItemIcon />
            {question.question}
          </SQuestionListItem>

          <AnimatePresence exitBeforeEnter>
            {question.key === active && (
              <SAnswerWrapper
                key={`answer_${question.key}`}
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'unset', opacity: 1 }}
                exit={{ height: 0 }}
                transition={{ ease: 'easeOut', duration: ANIMATION_DURATION_SECONDS }}
              >
                <SAnswer>
                  <SAnswerText>
                    {typeof question.answer === 'string' ? (
                      <div dangerouslySetInnerHTML={{ __html: question.answer }} />
                    ) : (
                      question.answer
                    )}
                  </SAnswerText>
                </SAnswer>
              </SAnswerWrapper>
            )}
          </AnimatePresence>
        </li>
      ))}
    </SQuestionList>
  )
}

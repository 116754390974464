import { getUserMe } from 'api/goodtrust/user'
import { DirectiveType } from 'components/directive/utils/types'
import { sendSimpleFlowStartedEvent } from 'logic/directive/analytics/sendSimpleFlowStartedEvent'
import { createDirectiveAndRedirect, CreateDirectiveContext } from 'logic/directive/create/create'
import Router from 'next/router'
import { unwrapResponse } from 'utils/fetcher'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'

export async function startDirectiveFlow(
  directiveType: DirectiveType | 'not_yet_chosen',
  ctx: CreateDirectiveContext
) {
  if (directiveType === 'not_yet_chosen') {
    if (ctx.isLogged) {
      const myPlansDesc = await getUserMe()
        .then(unwrapResponse.body)
        .then((userMe) => describeMyPlans(userMe))

      if (myPlansDesc.hasFullDirectiveAccess()) {
        Router.push('/me/estate-planning')
        return
      }
    }

    sendSimpleFlowStartedEvent('not_selected', ctx.simpleFlowPlace ?? 'unknown')

    if (ctx.isLogged) {
      Router.push('/pricing')
      return
    } else {
      Router.push('/signup')
      return
    }
  }

  // triggers sendSimpleFlowStartedEvent inside createSimpleFlowDirective
  return createDirectiveAndRedirect(directiveType, ctx)
}

import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export function InViewGuard(props: { setIsInView: (isIt: boolean) => void }) {
  const { setIsInView } = props
  const { ref, inView } = useInView()

  useEffect(() => {
    setIsInView(inView)
  }, [inView, setIsInView])

  return (
    <div
      ref={ref}
      css={css`
        visibility: hidden;
        height: 1px;
        position: absolute;
      `}
    ></div>
  )
}

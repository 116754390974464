import { useRouter } from 'next/router'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { cobrandingLogic, getCobrandedPartnerSlug } from 'utils/cobranding/logic'
import { useExtendedPromotion } from 'utils/promo'

export const useOutsideCobranding = () => {
  const router = useRouter()
  const { isLogged: loggedIn } = useAuth()
  const sessionValue = cobrandingLogic.useSessionValue()
  const { data: promo } = useExtendedPromotion(sessionValue?.promoCode)
  const cobrandedUser = cobrandingLogic.useCobrandedUser()
  const isAtEstatePlanningLP = router.pathname === '/estate-planning'
  if (loggedIn == null) {
    return undefined
  }

  const cobranding = cobrandedUser?.ctx.partnerResponse
    ? {
        reason: 'cobranded-user' as const,
        partner: cobrandedUser.ctx.partnerResponse,
      }
    : loggedIn &&
      isAtEstatePlanningLP &&
      typeof router.query.promo === 'string' &&
      getCobrandedPartnerSlug(router.query.promo)
    ? {
        reason: 'logged-in-user-at-estate-lp' as const,
        partner: 'take-from-offer' as const,
      }
    : !loggedIn && !!promo?.cobrandedPartner
    ? {
        reason: 'cobranded-session' as const,
        partner: 'take-from-offer' as const,
      }
    : undefined

  return cobranding
}

import { FAQuestionsProps } from 'components/faqTwoColumn/types'
import { FAQPageLinkedData } from 'components/jsonLinkedData/JsonLinkedData'
import { bound } from 'utils/class'
import { isNonNullable } from 'utils/general'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export function describeFAQ(content: FAQuestionsProps['content']) {
  return bound({
    getQuestions() {
      return (content && 'items' in content ? content.items.filter(isNonNullable) : content) ?? []
    },
    asLinkedData(): FAQPageLinkedData {
      return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: this.getQuestions().flatMap(({ answer, question }) =>
          !answer || !question
            ? []
            : [
                {
                  '@type': 'Question',
                  name: question,
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: documentToHtmlString(answer.json),
                  },
                },
              ]
        ),
      }
    },
  })
}

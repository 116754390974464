import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Text } from 'components/Typography'
import { motion } from 'framer-motion'
import { colors } from 'utils/colors'

export const SAnswer = styled.article`
  padding-left: 24px;
  border-left: 2px solid var(--c-blue500);
  margin-left: 6px;
  margin-top: 24px;
  span {
    color: black;
  }
`

export const SAnswerText = styled(Text)`
  p {
    color: ${colors.black};
  }
  ul {
    list-style-type: disc;
    white-space: normal;
  }
`

export const SAnswerWrapper = styled(motion.div)`
  width: 100%;
  overflow: hidden;
`
export const SQuestionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  & > li {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  & > li:not(:last-child) {
    margin-bottom: 18px;
  }
`

export const SQuestionListItemIcon = styled.span`
  display: block;
  width: 14px;
  height: 14px;
  flex-shrink: 0;

  position: relative;
  margin-right: 18px;

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;

    background: currentColor;
  }

  &:after {
    height: 14px;
    width: 2px;
    left: 50%;
    margin-left: -1px;

    transition: transform 0.2s;
  }

  &:before {
    width: 14px;
    height: 2px;
    top: 50%;
    margin-top: -1px;
  }
`

export const SQuestionListItem = styled(Text.withComponent('a'))<{
  active?: boolean
}>`
  display: flex;
  align-items: center;
  line-height: 1.2em;
  cursor: pointer;

  &:hover {
    color: var(--c-black);
  }

  ${(props) => {
    if (props.active) {
      return css`
        color: var(--c-black);
        font-weight: bold;
        ${SQuestionListItemIcon}:after {
          transform: rotate(90deg);
        }
      `
    }
    return null
  }}
`
